import './runLog.css';

function RunLogTableHeader() {
	return (
		<div id="runLogTableHeader">
			<div />
			<div>Date</div>
			<div>Distance</div>
			<div>Time</div>
			<div>Pace</div>
			<div />
			<div />
		</div>
	);
}

export default RunLogTableHeader; 
